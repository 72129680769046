
import makeStore from '../makeStore';
import { UiState, UiActions, UiSetters } from './uiTypes';

const initialState: UiState = {
  currentAction: '',
  isReady: false,
  showPopup: false,
  alert: '',
  showForm: false,
  mobileInitialState: { item: 0, subItem: [0] },
  showSlideshow: true,
  showSlideshowBackground: true,
}

const UI_ACTIONS = {
  SET_ACTION: 'SET_ACTION',
  SET_ISREADY: 'SET_ISREADY',
  SET_SHOWPOPUP: 'SET_SHOWPOPUP',
  SET_SHOWALERT: 'SET_SHOWALERT',
  SET_SHOWFORM: 'SET_SHOWFORM',
  SET_MOBILE_INITIAL_STATE: 'SET_MOBILE_INITIAL_STATE',
  SET_SLIDESHOW: 'SET_SLIDESHOW',
  SET_SLIDESHOW_BACKGROUND: 'SET_SLIDESHOW_BACKGROUND',
}

const uiReducer = (state: UiState, action: UiActions) => {
  switch (action.type) {
    case UI_ACTIONS.SET_ACTION:
      return {
        ...state,
        currentAction: action.currentAction,
      }
    case UI_ACTIONS.SET_ISREADY:
      return {
        ...state,
        isReady: action.isReady,
      }
    case UI_ACTIONS.SET_SHOWPOPUP:
      return {
        ...state,
        showPopup: action.showPopup,
      }
    case UI_ACTIONS.SET_SHOWALERT:
      return {
        ...state,
        alert: action.alert,
      }
    case UI_ACTIONS.SET_SHOWFORM:
      return {
        ...state,
        showForm: action.showForm,
      }
      case UI_ACTIONS.SET_MOBILE_INITIAL_STATE:
        return {
          ...state,
          mobileInitialState: action.mobileInitialState,
        }
      case UI_ACTIONS.SET_SLIDESHOW:
        return {
          ...state,
          showSlideshow: action.showSlideshow,
        }
      case UI_ACTIONS.SET_SLIDESHOW_BACKGROUND:
        return {
          ...state,
          showSlideshowBackground: action.showSlideshowBackground,
        }
    default:
      return state
  }
}

const [UiProvider, useUiDispatch, useUiState] = makeStore<UiState>(uiReducer, initialState);

function useUiActions(): UiSetters {
  const dispatch = useUiDispatch();

  const setAction = (action: string) => {
    dispatch({
      type: UI_ACTIONS.SET_ACTION,
      currentAction: action,
    })
  }
  
  const setShowPopup = (show: boolean) => {
    dispatch({
      type: UI_ACTIONS.SET_SHOWPOPUP,
      showPopup: show,
    })
  }
  
  const setIsReady = (ready: boolean) => {
    dispatch({
      type: UI_ACTIONS.SET_ISREADY,
      isReady: ready,
    })
  }
  
  const setAlert = (alert: string) => {
    dispatch({
      type: UI_ACTIONS.SET_SHOWALERT,
      alert,
    })
  }
    
  const setShowForm = (show: boolean) => {
    dispatch({
      type: UI_ACTIONS.SET_SHOWFORM,
      showForm: show,
    })
  }
  
  const setMobileInitialState = (item: number, subItem: number[]) => {
    dispatch({
      type: UI_ACTIONS.SET_MOBILE_INITIAL_STATE,
      mobileInitialState: { item, subItem }
    })
  }
    
  const setShowSlideshow = (show: boolean) => {
    dispatch({
      type: UI_ACTIONS.SET_SLIDESHOW,
      showSlideshow: show,
    })
  }
    
  const setShowSlideshowBackground = (show: boolean) => {
    dispatch({
      type: UI_ACTIONS.SET_SLIDESHOW_BACKGROUND,
      showSlideshow: show,
    })
  }
  
  return { setAction, setShowPopup, setIsReady, setAlert, setShowForm, setMobileInitialState, setShowSlideshow, setShowSlideshowBackground }
}

export { UiProvider, useUiState, useUiActions, UI_ACTIONS };
