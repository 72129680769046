import React, { useState } from 'react'
import { 
    SlideshowContainer, 
    SlideshowImage 
} from './styles'
import { useUiActions } from '../../reducers/ui'
import { setSlideshowSeen } from '../../utils/localStorage'
import { contentUrl } from '../../config/api'
import MobileArrows from './MobileArrows'

const Slideshow: React.FC<{ eventSetters: any, EventName: any, isMobile: any }> = ({ eventSetters, EventName, isMobile }) => {
    const [slideOne, showSlideOne] = useState(true)
    const [slideTwo, showSlideTwo] = useState(false)
    const [mobileArrows, showMobileArrows] = useState(false)
    const uiSetters = useUiActions()

    if (slideOne) {
        setTimeout(() => {
            showSlideOne(false)
            !mobileArrows && showSlideTwo(true)
        }, 5000)
    }

    if (slideTwo) {
        setTimeout(() => {
            showSlideTwo(false)
            uiSetters.setShowSlideshowBackground(false)
            showMobileArrows(true)
            !isMobile && setSlideshowSeen()
        }, 5000)
    }

    return (
        <>
            {slideOne && 
                <SlideshowContainer onClick={() => { 
                    showSlideOne(false); 
                    showSlideTwo(true) }}>
                        
                    <SlideshowImage src={`${!isMobile ? `${contentUrl}/images/slideshow-screen-1.png` : `${contentUrl}/images/slideshow-screen-1-mobile.png`}`} alt="slide-one" />
                </SlideshowContainer>
            }
            {slideTwo && !mobileArrows &&
                <SlideshowContainer onClick={() => { 
                    showSlideTwo(false); 
                    showMobileArrows(true); 
                    uiSetters.setShowSlideshowBackground(false); 
                    !isMobile && setSlideshowSeen() }}>

                    <SlideshowImage src={`${!isMobile ? `${contentUrl}/images/slideshow-screen-2.png` : `${contentUrl}/images/slideshow-screen-2-mobile.png`}`} alt="slide-two" />
                </SlideshowContainer>
            }
            {isMobile && mobileArrows &&
                <MobileArrows 
                    mobileTimeout={setTimeout(() => { uiSetters.setShowSlideshow(false); setSlideshowSeen() }, 5000)} 
                    mobileArrowCopy={"SWIPE TO EXPLORE"}
                    onClick={() => { uiSetters.setShowSlideshow(false); setSlideshowSeen() }}
                    />
            }
        </>
  )
}

export default Slideshow
