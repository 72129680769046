import React from 'react'
import { 
  MobileArrowsImage,
  MobileArrow
 } from './styles'

import arrowCycleRight from '../../assets/images/white-arrow-right.png'
import arrowCycleLeft from '../../assets/images/white-arrow-left.png'

const MobileArrows: React.FC<{ mobileTimeout: any, mobileArrowCopy: any, onClick: any }> = ({ mobileTimeout, mobileArrowCopy, onClick }) => {
  return (
    <>
      {mobileTimeout && 
        <MobileArrowsImage onClick={onClick}>
            <p>{mobileArrowCopy}</p>

            <MobileArrow left={"20%"}>
              <img width="9px" height="16px" src={arrowCycleLeft} alt="arrow-cycle-left" />
            </MobileArrow>
            <MobileArrow right={"20%"}>
              <img width="9px" height="16px" src={arrowCycleRight} alt="arrow-cycle-right" />
            </MobileArrow>
        </MobileArrowsImage>
      }
    </>
  )
}

export default MobileArrows
