//@ts-nocheck
import React, { useState, useRef, Suspense, lazy, useEffect } from 'react'
import './assets/styles/babylon.css'
import {
  Loading,
  ContentSidebar,
  MobileLandscapeOverlay,
  isMobile,
  Navigation,
  StripNavigation,
  Alert,
  gaInitalise,
  gaLogEvent,
  Form,
  EventReducer,
  EventName,
  LeavingDisclaimer,
  styled,
  AudioPlayer
} from '@lxlabs/vc-components/dist/index'
import { useUiActions, useUiState } from './reducers/ui'
import { MOBILELANDSCAPEOVERLAY_CONTENT, CONTACTFORM_CONTENT, INTRO_CONTENT, LEAVING_DISCLAIMER } from './config/app'
import * as CONTENT from './config/content'
import { contentUrl, assetsUrl } from './config/api'
import { ContentTypes } from './config/content'
import menu from './config/menu'
import showWarning from './utils/showWarning'
import config from './config/config.json'
import Slideshow from './components/Slideshow'

import BlackFade from './components/BlackFade'

import menuBg from './assets/images/menu-bg.png'
import logoSymbol from './assets/images/logo-symbol-white-small.png'
import menuBtnGreen from './assets/images/menu-burger.png'
import dupixentLogo from './assets/images/dupixent-logo.png'
import arrowRightWhite from './assets/images/arrow-right-white.png';


const AppScene = lazy(() => import('./AppScene'))
const { useEventActions, useEventState } = EventReducer

process.env.REACT_APP_HOSTED_IFRAME_GA && gaInitalise({ iframeHostedUrl: process.env.REACT_APP_HOSTED_IFRAME_GA })


function App() {
  const MOBILE_ROTATION = {};
  const SCREENS = config.sceneObjects.map(obj => { // eslint-disable-line
    if (obj.circle && obj.mobileRot) {
      MOBILE_ROTATION[obj.circle] = obj.mobileRot
      return obj.circle;
    }
    return undefined;
  }).filter(el => el != null);

  const [content, setContent] = useState<ContentTypes | ''>('')
  const [showLoader, setShowLoader] = useState(true)
  const [blackFadeClass, setBlackFadeClass] = useState('hidden')
  const [leavingDisclaimer, setLeavingDisclaimer] = useState(null)
  const [currentMobileHover, setCurrentMobileHover] = useState(SCREENS[2]);

  const [activeTab, setActiveTab] = useState<string>('')
  const uiState = useUiState()
  const uiSetters = useUiActions()
  const eventState = useEventState()
  const eventSetters = useEventActions()

  const fadeTimer = useRef(null)

  useEffect(() => {
    const scene = document.querySelector("[id^='aad-scene']")
    if (scene) {
      if (uiState.showPopup && isMobile) {
        scene.style.visibility = "hidden"
      } else {
        scene.style.visibility = "visible"
      }
    }

  }, [uiState.showPopup])

  useEffect(() => {
    document.addEventListener('click', (ev) => {
      if (ev.target.tagName === 'A') {
        if (ev.target.dataset.disclaimer !== "false") {
          ev.preventDefault();
          setLeavingDisclaimer({
            href: ev.target.href,
            text: ev.target.innerHTML
          })
        }
        
      }
    })
  }, [])

  const onFlyThroughEnd = (cancel = true) => {

    gaLogEvent({
      event: "action",
      DLV_content_action_type: "flyThroughSkipped",
    })

    fadeInOut(1200)
    setTimeout(() => {
      if (cancel) {
        eventSetters.setEvent(EventName.CANCEL_FLY_THROUGH)
      }
      eventSetters.setFlyThroughActive(false)
      eventSetters.setFlyThroughViewed(true)
    }, 600)
  }

  const onClose = () => {
    uiSetters.setShowPopup(false)
    eventSetters.setEvent(EventName.MOVE_TO_START)
  }

  const showFade = () => {
    setBlackFadeClass('visible')
  }

  const hideFade = () => {
    setBlackFadeClass('click-through')
  }

  const fadeInOut = (time: number) => {
    showFade()
    clearInterval(fadeTimer.current)
    fadeTimer.current = setTimeout(() => {
      hideFade()
    }, time)
  }

  const onNavigationSelect = (instance: string, item) => {
    if (instance === "KEEP_IN_TOUCH") {
      uiSetters.setShowForm(true)
      return
    }

    if (instance === "STUDY_DESIGNS") {
      uiSetters.setAction('STUDY_DESIGNS');
      return;
    }

    if (instance === "EXTERNAL_LINK") {
      setLeavingDisclaimer({
        href: item.linkTitleUrl,
        text: item.contactUsTitle
      })
      return
    }

    if (instance === 'default') {
      onClose();
      setCurrentMobileHover('');
      setTimeout(() => {
        setCurrentMobileHover(SCREENS[2]);
      }, 0)
      
      return
    } 

    uiSetters.setShowForm(false)
    uiSetters.setShowPopup(false)
    uiSetters.setAction('')
    eventSetters.setEvent(EventName.MOVE_TO_OBJECT, instance);
    
  }

  const onNext = () => {
    const currentIndex = SCREENS.indexOf(currentMobileHover);
    if (currentIndex + 1 < SCREENS.length) {
      setCurrentMobileHover(SCREENS[currentIndex+1])
    } else {
      setCurrentMobileHover(SCREENS[0]);
    }
    
  }

  const onPrevious = () => {
    const currentIndex = SCREENS.indexOf(currentMobileHover);
    if (currentIndex - 1 >= 0) {
      setCurrentMobileHover(SCREENS[currentIndex-1])
    } else {
      setCurrentMobileHover(SCREENS[SCREENS.length-1]);
    }
  }

  return (
      <Container>
        {eventState.flyThroughViewed && <AudioPlayer src={`${assetsUrl}/audio/BackgroundAudio.mp3`} uiState={uiState} maxVolume={0.5} mobileOffsetTop={90}/>}
        {isMobile && eventState.flyThroughViewed && !eventState.cameraMoving && <NextPreviousButtons>
          <button
            className="previous"
            type="button"
            onClick={onPrevious}
            >
            <p>PREV</p>
          </button>
          <button
            className="next"
            type="button"
            onClick={onNext}
            >
            <p>NEXT</p>
          </button>

        </NextPreviousButtons>}

        {!localStorage.getItem('SlideshowSeen') && 
          <>
            {uiState.showSlideshow && uiState.isReady && !showLoader && <Slideshow eventSetters={eventSetters} EventName={EventName} isMobile={isMobile} />}
            {uiState.showSlideshowBackground && <SlideshowBackground style={{ display: `${uiState.showSlideshow ? 'block' : 'none'}` }} />}
          </>
        }

        {isMobile && (
          <MobileLandscapeOverlay
          content={MOBILELANDSCAPEOVERLAY_CONTENT.overlayMessage}
          mobileLandscapeImage={`${contentUrl}/images/mobile-landscape-overlay.svg`}
          />
        )}
        {eventState.flyThroughViewed && uiState.isReady && <>
          {isMobile ?
            <>
              <StripNavigation
                type={'USAD'}
                skewDegrees={'45deg'}
                headerHeight={90}
                filter={false}
                navItems={menu.menuItems}
                onSelect={onNavigationSelect}
                logo={dupixentLogo}
                logoWidth={'160px'}
                menuBtn={menuBtnGreen}
                multipleLines={false}
                active={activeTab ? activeTab : 'default'}
                showForm={uiState.showForm}
                menuHandler={(setShowForm, showNav, setShowNav) => {
                  if (!eventState.cameraMoving) {
                    if (uiState.currentAction !== '') {
                      uiSetters.setMobileInitialState(0, [0]);
                      onNavigationSelect('default');
                      setShowForm(false);
                      setShowNav(false);
                    } else {
                      setShowForm(false);
                      setShowNav(!showNav);
                    }
                  }
                }}
                subNavHandler={(instance, subNavIndex, subSubNavIndex, items) => {
                  uiSetters.setMobileInitialState(subNavIndex, [subSubNavIndex]);
                  onNavigationSelect(instance, items);
                }}
                setShowForm={(show) => {
                  if (uiSetters.showForm !== show) {
                    setActiveTab("KEEP_IN_TOUCH")
                  }
                }}
                
                />
            </>
          :
            <>
            {!eventState.cameraMoving &&
              <Navigation
              menuItems={menu.menuItems}
              onSelect={onNavigationSelect}
              loaded={true}
              />
            }
            </>
          }
        </>}

        {uiState.showPopup && (
          <>
            <ContentSidebar 
              contents={CONTENT[content as ContentTypes]} 
              onClose={onClose}
              clipPath={"polygon(0 12px, 12px 0, 100% 0, 100% 100%, 0% 100%)"} 
              type="USAD"
              initialState={uiState.mobileInitialState}
              />
          </>
        )}
        {eventState.flyThroughActive && <SkipFlyThrough onClick={onFlyThroughEnd}>SKIP</SkipFlyThrough>}
        {showLoader && <Loading isReady={uiState.isReady} content={INTRO_CONTENT} setShowLoader={setShowLoader} />}

        <BlackFade
          onClick={() => {
            if (eventState.flyThroughActive) {
              onFlyThroughEnd()
            }
          }}
          className={`${blackFadeClass}`}
          />
        {uiState.showForm && <Form
          url={process.env.REACT_APP_CONTACT_US_ENDPOINT}
          content={CONTACTFORM_CONTENT}
          showForm={uiState.showForm}
          offset={isMobile ? 90 : 0}
          setShowForm={() => {
            eventSetters.setEvent(EventName.JUMP_TO_START)
            setTimeout(() => {
              uiSetters.setShowForm(false)
            }, 200)
            
          }}
          />}

        <Suspense fallback={<></>}>
          <AppScene
            eventState={eventState}
            eventSetters={eventSetters}
            setActiveTab={setActiveTab}
            setContent={setContent}
            uiState={uiState}
            uiSetters={uiSetters}
            isMobile={isMobile}
            currentMobileHover={currentMobileHover}
            MOBILE_ROTATION={MOBILE_ROTATION}
            onFlyThroughEnd={onFlyThroughEnd}
            showLoader={showLoader}
            hideFade={hideFade}
            />
        </Suspense>
        <Alert
          message="For an optimal experience, please switch your browser to Google Chrome."
          iosMessage="For an optimal experience, please switch your browser to Safari."
          closeText="OK"
          show={showWarning()}
          menuBg={menuBg}
          logoSymbol={logoSymbol}
          />
        {leavingDisclaimer && 
          <LeavingDisclaimer 
            setShowModal={setLeavingDisclaimer} 
            disclaimer={leavingDisclaimer} 
            disclaimerCopy={LEAVING_DISCLAIMER} 
          />
        }
      </Container>
  )
}

const SlideshowBackground = styled.div`
  background: black;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99998;
`

const NextPreviousButtons = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 10%;
  left: 5%;
  width: 90%;
  height: 45px;
  pointer-events: none;
  .previous, .next {
    cursor: default;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    p {
      display: inline;
      font-family: 'DIN';
    }
  }
  .previous {
    width: 30%;
    height: 100%;
    pointer-events: auto;
    background: #009966;
    color: white;
    border-radius: 6px 0 0 6px;
    &::before {
      content: "";
      width: 9px;
      height: 14px;
      background-image: ${`url(${arrowRightWhite})`};
      transform: scaleX(-1);
      display: inline-block;
      margin: 0 20px -1px -20px;
    }
  }
  .next {
    width: 30%;
    pointer-events: auto;
    background: #009966;
    color: white;
    border-radius: 0 6px 6px 0;
    &::after {
      content: "";
      width: 9px;
      height: 14px;
      background-image: ${`url(${arrowRightWhite})`};
      display: inline-block;
      margin: 0 -20px -1px 20px;
    }
  }
`

const SkipFlyThrough = styled.p`
  bottom: 5%;
  color: white;
  font-size: 1.25rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity 0.5s ease-in-out 0.75s;
  text-shadow: 0 0 4px black;
  z-index: 9999999;
  cursor: pointer;
  :hover {
    color: #f9f9f9;
    font-size: 1.3rem;
  }
`

const Container = styled.div`
  height: 100%;
`

export default App

