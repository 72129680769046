import React from 'react'
import App from './App'


export const LandingPage = () => {
  return (
    <>
      <App />
    </>
  )
}
